export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};

// Anything above this should be a special request
export const MAX_EXPORT_DATA_COUNT = 50000;
export const MAX_FETCH_DATA_COUNT = 1000;

export const systemRoles = {
  ADMIN: 'ADMIN',
  BASIC: 'BASIC',
};

export const rootAdminRoles = [systemRoles.ADMIN];

export const rootUserRoles = [...rootAdminRoles, systemRoles.BASIC];

export const hasAnyRole = (user: any, roles: string[] = []): boolean => {
  const roleData = user.role || user.roles;
  const rolesList = roles.map((it) => it.toLocaleLowerCase());
  if (typeof roleData === 'string') {
    const userRole = roleData.toLocaleLowerCase();
    return rolesList.indexOf(userRole) >= 0;
  }
  const rolesData = roleData?.map((it: any) => it.toLocaleLowerCase()) ?? [];
  return rolesData.some((r: any) => rolesList.indexOf(r) >= 0);
};

export const isRootAdminUser = (user: any): boolean => hasAnyRole(user, rootAdminRoles);

export const isRootUser = (user: any): boolean => hasAnyRole(user, rootUserRoles);

export const systemPermissions = {
  COLLECTION_VIEW: 'COLLECTION_VIEW',
  COLLECTION_TRIGGER: 'COLLECTION_TRIGGER',

  DISBURSEMENT_VIEW: 'DISBURSEMENT_VIEW',
  DISBURSEMENT_TRIGGER: 'DISBURSEMENT_TRIGGER',
  SINGLE_DISBURSEMENT_INITIATOR: 'SINGLE_DISBURSEMENT_INITIATOR',
  SINGLE_DISBURSEMENT_APPROVER: 'SINGLE_DISBURSEMENT_APPROVER',

  BULK_DISBURSEMENT_PAY: 'BULK_DISBURSEMENT_PAY',
  BULK_DISBURSEMENT_APPROVE: 'BULK_DISBURSEMENT_APPROVE',
  BULK_DISBURSEMENT_INITIATE: 'BULK_DISBURSEMENT_INITIATE',
  TRANSACTION_SANITIZE: 'TRANSACTION_SANITIZE',

  WALLET_CREATE: 'WALLET_CREATE',
  WALLET_VIEW: 'WALLET_VIEW',
  WALLET_UPDATE: 'WALLET_UPDATE',
  WALLET_TOPUP: 'WALLET_TOPUP',
  WALLET_ADMIN: 'WALLET_ADMIN',
  WALLET_LIQUIDATE: 'WALLET_LIQUIDATE',

  WALLET_NOTIFICATION_EDIT: 'WALLET_NOTIFICATION_EDIT',
  WALLET_NOTIFICATION_VIEW: 'WALLET_NOTIFICATION_VIEW',
  WALLET_LIQUIDATIONS_VIEW: 'PARTNER_VIEW_LIQUIDATIONS',

  PARTNER_VIEW_LIQUIDATIONS: 'PARTNER_VIEW_LIQUIDATIONS',
  PARTNER_VIEW_TOPUPS: 'PARTNER_VIEW_TOPUPS',
  PARTNER_SELF_LIQUIDATE: 'PARTNER_SELF_LIQUIDATE',
  PARTNER_CLIENT_RESET: 'PARTNER_CLIENT_RESET',

  PARTNERS_VIEW: 'PARTNERS_VIEW',
  PARTNERS_EDIT: 'PARTNERS_EDIT',

  PARTNER_MEMBERS_EDIT: 'PARTNER_MEMBERS_EDIT',
  PARTNER_MEMBERS_VIEW: 'PARTNER_MEMBERS_VIEW',

  VENDOR_BALANCES_VIEW: 'VENDOR_BALANCES_VIEW',
};

export const walletNotificationRoles = [
  systemPermissions.WALLET_NOTIFICATION_VIEW, systemPermissions.WALLET_NOTIFICATION_EDIT
];

export const walletCanLiquidationTriggerRoles = [
  systemPermissions.WALLET_LIQUIDATE,
  systemPermissions.PARTNER_SELF_LIQUIDATE
];

export const localRoutes = {
  //analytics
  dashboard: '/analytics/dashboard',
  transactionReports: '/analytics/transaction-reports',
  reconciliationReports: '/analytics/reconciliation-reports',

  //payments
  transactions: '/payments/transactions',
  collections: '/payments/collections',
  disbursements: '/payments/disbursements',

  bulkDisbursements: '/payments/bulk-disbursements',
  bulkDisbursementsDetails: '/payments/bulk-disbursements/:bulkId',
  bulkDisbursementsCreate: '/payments/bulk-disbursements/create',

  topups: '/payments/topups',
  liquidations: '/payments/liquidations',

  beneficiaries: '/payments/beneficiaries',
  beneficiaryDetails: '/payments/beneficiaries/:beneficiaryId',

  //management
  staff: '/admin/staff',
  staffDetails: '/staff/:staffId',

  wallets: '/admin/wallets',
  walletDetails: '/admin/wallets/:walletId',

  partners: '/admin/partners',
  partnerDetails: '/admin/partners/:partnerId',

  settings: '/admin/settings',
  vendorBalances: '/admin/vendor-balances',
  stanbicMiniStatement: '/admin/stanbic-mini-statement',
  banks: '/admin/banks',
};

const servers: any = {
  dev: {
    Verify: 'https://verify-staging.iotec.io',
    Lumen: 'https://lumen-staging.iotec.io',
    Messaging: 'https://messaging-staging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm_: 'https://localhost:6003',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
    Crm__: 'https://crm.iotec.io',
    Payments: 'https://localhost:6002',
    Payments_: 'https://pay-staging.iotec.io',
    Msg: 'https://localhost:7208'
  },
  staging: {
    Verify: 'https://verify-staging.iotec.io',
    Lumen: 'https://lumen-staging.iotec.io',
    Messaging: 'https://messaging-staging.iotec.io',

    Auth: 'https://id.iotec.io',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
    Payments: 'https://pay-staging.iotec.io',
    Msg: 'https://messaging-staging-api.iotec.io'
  },
  production: {
    Verify: 'https://verify.iotec.io',
    Lumen: 'https://lumen.iotec.io',
    Messaging: 'https://messaging.iotec.io',

    Auth: 'https://id.iotec.io',
    Crm: 'https://crm.iotec.io',
    Payments: 'https://pay.iotec.io',
    Msg: 'https://messaging-api.iotec.io'
  }
};

const envVar = process.env.REACT_APP_ENV || 'dev';

const environment = envVar.trim();
console.log(`############# Env : ${environment} ################`);

const env = servers[environment];
const authUrl = env.Auth;
const crmUrl = env.Crm;
const paymentsUrl = env.Payments;

const verifyUrl = env.Verify;
const lumenUrl = env.Lumen;
const messagingUrl = env.Messaging;
const msgUrl = env.Msg;

export const remoteRoutes = {
  verify: verifyUrl,
  lumen: lumenUrl,
  messaging: messagingUrl,
  auth: authUrl,
  payments: paymentsUrl,
  crm: crmUrl,
  userRoles: `${paymentsUrl}/api/auth/roles/partnerId`,
  crmProfile: `${paymentsUrl}/api/contacts/profile`,
  contacts: `${crmUrl}/api/crm/contact`,
  contactSearch: `${crmUrl}/api/contact/search`,
  contactById: `${crmUrl}/api/contact/id`,
  contactsPerson: `${crmUrl}/api/crm/person`,
  contactsCompany: `${crmUrl}/api/crm/company`,
  contactsChc: `${crmUrl}/api/crm/person/chc`,
  contactsEmail: `${crmUrl}/api/crm/email`,
  contactsTag: `${crmUrl}/api/crm/tag`,
  contactsUrl: `${crmUrl}/api/crm/url`,
  contactsPhone: `${crmUrl}/api/crm/phone`,
  contactsAddress: `${crmUrl}/api/crm/address`,
  contactsIdentification: `${crmUrl}/api/crm/identification`,

  users: `${paymentsUrl}/api/auth/users`,
  userClaims: `${authUrl}/api/User/Claim`,
  userMultiClaims: `${authUrl}/api/multipleClaims`,
  userCustomClaims: `${authUrl}/api/customClaims`,

  bouReports: `${paymentsUrl}/api/reports/electronic-money-operations`,
  dailyRecon: `${paymentsUrl}/api/reports/daily-reconciliation`,

  wallets: `${paymentsUrl}/api/wallets`,
  walletCallback: `${paymentsUrl}/api/wallet-callback`,
  walletLiquidationAccount: `${paymentsUrl}/api/wallet-liquidation-accounts`,
  walletLiquidations: `${paymentsUrl}/api/wallet-liquidations`,
  walletLiquidationsInitiate: `${paymentsUrl}/api/wallet-liquidations/initiate`,
  walletLiquidationsApprove: `${paymentsUrl}/api/wallet-liquidations/approve`,
  walletTopUps: `${paymentsUrl}/api/wallet-top-up`,
  walletTopUpsInitiate: `${paymentsUrl}/api/wallet-top-up/initiate`,
  walletTopUpsApprove: `${paymentsUrl}/api/wallet-top-up/approve`,
  walletLiquidationAccountUpdate: `${paymentsUrl}/api/wallet-liquidation-accounts/update`,
  walletLiquidationAccountApprove: `${paymentsUrl}/api/wallet-liquidation-accounts/approve`,
  walletLiquidationAccountByWallet: `${paymentsUrl}/api/wallet-liquidation-accounts/by-wallet`,
  walletLiquidationAccounts: `${paymentsUrl}/api/wallet-liquidation-accounts`,
  walletLiquidationInitiate: `${paymentsUrl}/api/wallet-liquidations/initiate`,

  walletPricing: `${paymentsUrl}/api/wallet-charges`,
  walletPricingFetch: `${paymentsUrl}/api/wallets/view-charges`,
  walletsActivateDeactivate: `${paymentsUrl}/api/wallets/activate-deactivate`,
  walletBalance: `${paymentsUrl}/api/wallet-balance`,

  walletToggleCanDisburse: `${paymentsUrl}/api/wallets/toggle-can-disburse`,
  walletToggleCanCollect: `${paymentsUrl}/api/wallets/toggle-can-collect`,
  walletToggleHasMakerChecker: `${paymentsUrl}/api/wallets/toggle-has-maker-checker`,
  walletToggleFineGrainAccess: `${paymentsUrl}/api/wallets/toggle-fine-grain-access`,

  walletSlug: `${paymentsUrl}/api/wallet-slug/update`,
  walletSlugDetails: `${paymentsUrl}/api/wallet-slug/details`,
  walletSlugCollection: `${paymentsUrl}/api/wallet-slug/collect`,
  walletSlugCollectionStatus: `${paymentsUrl}/api/wallet-slug/status`,

  partners: `${paymentsUrl}/api/partners`,
  partnerUpdateShortName: `${paymentsUrl}/api/partner-update/short-name`,
  partnersActivateDeactivate: `${paymentsUrl}/api/partners/activate-deactivate`,

  banks: `${paymentsUrl}/api/bank-list`,
  transactions: `${paymentsUrl}/api/wallet-transactions/list`,
  transactionsPaged: `${paymentsUrl}/api/wallet-transactions/paged-list`,
  nameVerification: `${paymentsUrl}/api/name-verification`,
  partnerMembers: `${paymentsUrl}/api/partner-members`,
  walletMembers: `${paymentsUrl}/api/wallet-members`,
  walletMembersCombo: `${paymentsUrl}/api/wallet-members/combo`,
  partnerMemberInvitation: `${paymentsUrl}/api/partner-member-invitation`,
  partnerMembersActivateDeactivate: `${paymentsUrl}/api/partner-members/activate-deactivate`,

  memberProfile: `${paymentsUrl}/api/auth/profile`,
  walletsCombo: `${paymentsUrl}/api/wallets-combo`,
  partnersCombo: `${paymentsUrl}/api/partners-combo`,

  collectionsHistory: `${paymentsUrl}/api/collections/history`,
  collectionsPagedHistory: `${paymentsUrl}/api/collections/paged-history`,
  collectionTrigger: `${paymentsUrl}/api/collections/collect`,
  collectionStats: `${paymentsUrl}/api/collections/stats`,
  collectionSummary: `${paymentsUrl}/api/collections/summary`,
  collectionPreview: `${paymentsUrl}/api/collections/preview-collect`,
  collectionSanityResetFailed: `${paymentsUrl}/api/sanitize-collections/reset-failed`,

  disbursementsHistory: `${paymentsUrl}/api/disbursements/history`,
  disbursementsPagedHistory: `${paymentsUrl}/api/disbursements/paged-history`,
  bulkDisbursementsSample: `${paymentsUrl}/api/bulk-disbursements/sample`,
  bulkDisbursementsProcess: `${paymentsUrl}/api/bulk-disbursements/process`,
  bulkDisbursementsPreview: `${paymentsUrl}/api/bulk-disbursements/preview`,
  bulkDisbursementsHistory: `${paymentsUrl}/api/bulk-disbursements/history`,
  bulkDisbursementsApprovalView: `${paymentsUrl}/api/bulk-disbursement-approval/view`,
  bulkDisbursementsApprovalApprove: `${paymentsUrl}/api/bulk-disbursement-approval/approve`,
  bulkDisbursementsApprovalPay: `${paymentsUrl}/api/bulk-disbursement-approval/pay`,
  bulkDisbursementsApprovalReject: `${paymentsUrl}/api/bulk-disbursement-approval/reject`,
  disbursementsStats: `${paymentsUrl}/api/disbursements/stats`,
  disbursementsSummary: `${paymentsUrl}/api/disbursements/summary`,
  disbursementTrigger: `${paymentsUrl}/api/disbursements/disburse`,
  disbursementApprove: `${paymentsUrl}/api/disbursements/approve`,
  disbursementPreview: `${paymentsUrl}/api/disbursements/preview-disburse`,

  disbursementLiveStatus: `${paymentsUrl}/api/disbursements/live-status`,
  disbursementSanityResetFailed: `${paymentsUrl}/api/sanitize-disbursements/reset-failed`,
  disbursementSanityRollbackSuccessful: `${paymentsUrl}/api/sanitize-disbursements/rollback-success`,


  beneficiaries: `${paymentsUrl}/api/beneficiaries`,
  BankBeneficiary: `${paymentsUrl}/api/beneficiaries/create-bank-beneficiary`,
  PhoneBeneficiary: `${paymentsUrl}/api/beneficiaries/create-phone-beneficiary`,
  WalletBeneficiary: `${paymentsUrl}/api/beneficiaries/create-wallet-beneficiary`,

  walletNotifications: `${paymentsUrl}/api/wallet-notifications`,
  walletNotificationsPaymentTemplates: `${paymentsUrl}/api/wallet-notification-combo/payment-templates`,
  walletNotificationsTemplates: `${msgUrl}/api/templates`,
  walletNotificationsRecipients: `${msgUrl}/api/customers/combo`,

  UpdateBankBeneficiary: `${paymentsUrl}/api/beneficiaries/update-bank-beneficiary`,
  UpdatePhoneBeneficiary: `${paymentsUrl}/api/beneficiaries/update-phone-beneficiary`,
  UpdateIotecPayBeneficiary: `${paymentsUrl}/api/beneficiaries/update-iotec-pay-beneficiary`,

  beneficiariesCombo: `${paymentsUrl}/api/beneficiaries-combo`,
  paymentsBalanceDisburseAirtel: `${paymentsUrl}/api/vendor-balance/airtel-disbursement`,
  paymentsBalanceCollectAirtel: `${paymentsUrl}/api/vendor-balance/airtel-collection`,

  paymentsBalanceCollectMTN: `${paymentsUrl}/api/vendor-balance/mtn-collection`,
  paymentsBalanceDisburseMTN: `${paymentsUrl}/api/vendor-balance/mtn-disbursement`,
  paymentsBalanceStanbicEscrow: `${paymentsUrl}/api/vendor-balance/stanbic-escrow`,
  paymentsStanbicMiniStatement: `${paymentsUrl}/api/vendor-balance/stanbic-mini-statement`,

  paymentDomainUrl: `${paymentsUrl}/p/`,

  apiDocumentation: `${paymentsUrl}/api-docs/index.html`,
  hangfire: `${paymentsUrl}/admin/hangfire`,

};
